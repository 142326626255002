*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  overflow: hidden;
  /* &::-webkit-scrollbar {
    width: 0;
  } */
}
.drop-or-select{
  border: 1px solid black;
  border-radius: 50px;
  width:50%;
  height:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  box-shadow: 10px 10px 20px rgba(0.7, 0.7, 0.7, 0.7); /* Stronger shadow */
  /* margin-top: 50px; */
  /* backdrop-filter: blur(3px);  */
  font-size: 20px;
  margin-top: 40px;
  background:  url(./assetes/images/shapesbg2.png) no-repeat center center/cover;


}
.drop-or-select h1 {
  font-size: 50px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#file-input-label {
  background-color: rgb(0, 73, 128);
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;

  /* padding: 100px; */
  /* transition: all 0.2s ease-in; */
}
p{
  margin-top: 20px;
}
#file-input-label:hover {
  background-color: rgba(0, 73, 128, 0.735);

}
#file-input {
  display: none;
}
.drop-zone{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    /* justify-content: space-a; */
    flex-direction: column;
    /* background: url(./ed-it\ bg.jpg) no-repeat center center/cover; */
    

}

.active-drop-zone{
  background: rgba(0, 106, 187, 0.549);
  box-shadow: 10px 10px 10px rgba(0.7, 0.7, 0.7, 0.3); 
}
/* .image-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
    border: 3px red solid;

} */
img {
  height: 100vh ;
  object-fit: cover;

  /* position: absolute;
  left:0;
  bottom: 0; */
  /* margin: 0; */
  /* padding: 0; */
  /* backdrop-filter: blur(3px);  */
  /* border: 3px red solid; */

}
.image-ref{
  /* height: 100vh ; */
  display:flex ;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;

}
.edit{
  /* border: 3px green solid; */
  /* height: 100vh;
  width:100%; */
  height: fit-content ;
  position: absolute;
  left: 0;
  top: 50%; 
  transform: translateY(-50%);
  width: 300px;

  /* transform: translateY(-50%) ; */
  /* max-height: max-content; */
  display:flex ;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* padding: 0 3px; */
  /* background: rgba(0, 0, 0, 0.5) url(./assetes/images/tools\ bg.jpg) no-repeat center center/cover; */
  /* height: 100%; */
  z-index: 1;
border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
  /* background: rgba(0, 0, 0, 0.2); */
  /* background: rgba(255, 255, 255, 0.7); */
  background:  url(./assetes/images/shapesbg2.png) no-repeat center center/cover;
    /* backdrop-filter: blur(3px);  */



  /* object-fit: cover; */
  padding: 5px;
  transition: all 0.5s ease-in-out;

}
.sliders{
  /* width: 350px; */
  /* border: 3px green solid; */
}
.slider{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* border: 3px green solid; */
}
.slider input{
  width:200px;
}
.row{
  display: flex;
  justify-content: center;
}
.form-control{
  width:max-content;
  align-self: center;
}
.rotated-image{
  width: 100vh !important  ; 
  height: auto !important   ;
}

#reflect-vertical{
width: 20px;
}
.icon, button.remove-bg{
  color: black;
  
}
.vertical{
  transform: rotate(90deg);

}
h1{
/* text-align: center; */
padding: 50px 0;
font-size: 70px;
font-family: 'Montserrat', sans-serif;
text-align: center;
background-color: transparent;
}

.layer{
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0,0.7);
  position: fixed;
  left:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.layer h1{
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}
.main-component{
   width: 100%;
  height: 100vh; 
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(./assetes/images/editbg.jpg) no-repeat center center/cover;
}
.images{
  display: flex;
  justify-content: space-around;
  /* align-items: space-around; */
}
.edit-container{
   /* border: 3px red solid; */
   height: 100vh;
   /* width:100%; */
   display:flex ;
   /* flex-direction: column; */
   justify-content: center;
   align-items: center;
   /* background: url(./assetes/images/brush.jpg) no-repeat center center/cover; */
   background-color: white;
}
.main-component .bg{
  background-color: white;
}
.radio-btns{
  display: flex;
  justify-content: space-around;
}
.edit-container h1{
  padding: 0;
}
.overlay{
  z-index: 1;

}
.output-image-container{
  /* position: fixed;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 1; */
  /* width: 500px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* text-align: center; */
  flex-direction: column;

  background-color: white;
  border-radius: 20px;
  /* position: absolute;
  left:0;
  bottom: 0; */
  overflow: auto;

}
#output-image{
  /* position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; */
}
button.cancel{
  color:red
}
button.hide-btn{
  color:black;
  align-self: flex-end;
  

  
}
.hide {
  left: -700px;
  /* top: 50%; 
  transform: translateY(-50%); */
  /* transform: translateX(-70px); */
  transition: all 0.5s ease-in-out;
}
button.show{
  color:black;
  position: absolute;
  left: 0px;
  top: 50%;  
   transform: translateY(-50%);
  z-index: 1;
  transition: all 0.5s ease-in-out;
  background:  url(./assetes/images/shapesbg2.png)  center ;



}

button.show-hidden{
  color:black;
  position: absolute;
  left: -150px;
  top: 50%;  
   transform: translateY(-50%);
  z-index: 1;
  transition: all 0.5s ease-in-out;
  background: rgba(255, 255, 255, 0.7);


}
.loading-spinner-main {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
button.back{
  position: absolute;
  left: 5px;
  top: 5px;
  color: black;
  background:  url(./assetes/images/shapesbg2.png)  center ;

}

@media (max-width: 768px) {
  img {
    width: 100% !important ;
  height: auto !important;
  max-width: 100vw ;
  max-height: 200px !important;
    object-fit: scale-down;

    
  
  }
  .rotated-image{
  
  width: 100% !important ;
  height: auto !important;
  max-width: 200px;
  max-height: 100vw;
  }
  .image-container{
   display: flex;
   justify-content: center;
    width: 100vw; /* Set the width of the rectangle */
    height: 200px; /* Set the height of the rectangle */
  
 }
  .edit-container{
   
    align-items: end;
  
 }
 .drop-or-select{
 
  width:75%;
  height:30%;
 
 


}
h1{
  padding-top: 200px;
  
  }

  
  .form-control{
    display: none !important;
  }
  button.back{
    top: 170px;
    
  
  }
  button.hide-btn{
    display: none;
    
  
    
  }
}

